<template>
<v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Operador</v-card-title>
        <v-card-text>
            <v-row>
            <v-col cols="3">
                <v-text-field
                                v-model="operador.nombre"
                                label="Nombre"
                                class="uppercase"
                                
                            /> 
            </v-col>
            <v-col cols="3">
<v-text-field
                                v-model="operador.apellidos"
                                label="Apellidos"
                                class="uppercase"
                                
                            /> 
            </v-col>
            <v-menu
                        ref="menuThree"
                        v-model="menuThree"
                        :close-on-content-click="false"
                        :return-value.sync="dateTwo"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                v-model="operador.fecha_licencia"
                                label="Fecha de Inicio de Vigencia de Licencia"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="operador.fecha_licencia" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menuThree = false"
                            >
                                Cancelar
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menuThree.save(perador.fecha_licencia)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                     <v-menu
                        ref="menuFour"
                        v-model="menuFour"
                        :close-on-content-click="false"
                        :return-value.sync="dateTwo"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-text-field
                                v-model="operador.fecha_vigencia"
                                label="Fecha Fin de Vigencia de Licencia"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="operador.fecha_vigencia" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menuFour = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menuFour.save(operador.fecha_vigencia)"
                            >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    
            </v-row>
            <v-row>
                  <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Subir INE"
   @change="onFileINEChange"
></v-file-input>
                        </v-col>
          
 <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Subir Licencia"
   @change="onFileLicChange"
></v-file-input>
                        </v-col>
             <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Subir Examen Médico"
   @change="onFileExamenChange"
></v-file-input>
                        </v-col>  


            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn color="success" @click="saveoperador">
                     <v-icon>mdi-content-save-outline</v-icon>
                  Guardar
                </v-btn>
              </v-col>
              </v-row>
        </v-card-text>
      </base-card>
    </v-col>
</v-row>
</template>
<script>
import { url } from "../../main.js";
import moment from "moment-timezone";

export default {
   metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Operador"
    },
    data () {
      return {
          enviar: {},
        idoperador: this.$route.params.idoperador,
          edit:false,
            operador: {
        idoperador: 0,

        nombre: "",
        apellidos: "",
        fecha_licencia: '',
        fecha_vigencia: '',
        documentacion: {
          ine: null,
          licencia: null,
          examen_medico: null,
        },
      }

      }
    },
          mounted(){

this.permiso = localStorage.getItem("permission");
    console.log(this.idoperador)
    if(this.idoperador==0)
    {


    }else{
    this.editOperador();
   
    }
    },
    methods:{
        editOperador()
        {
this.axios
        .get(url + "apiOperadores.php/operador/" + this.idoperador)
        .then((respuesta) => {
          console.log(respuesta);
          this.operador.idoperador = respuesta.data.idoperador;
          this.operador.fecha_licencia =
            respuesta.data.fecha_licencia;
         
          this.operador.fecha_vigencia = 
            respuesta.data.fecha_vigencia;
         
          this.operador.nombre = respuesta.data.nombre;
          this.operador.apellidos = respuesta.data.apellidos;
          this.operador.documentacion.ine = respuesta.data.ine;
          this.operador.documentacion.licencia = respuesta.data.licencia;
          this.operador.documentacion.examen_medico =
            respuesta.data.examen_medico;

          this.edit = true;
        });
      },
       onFileLicChange(e) {
      console.log(e);

      this.operador.documentacion.licencia = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileINEChange(e) {
      console.log(e);

      this.operador.documentacion.ine = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileExamenChange(e) {
      console.log(e);

      this.operador.documentacion.examen_medico = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    }, validarcampos() {
      if (
        this.operador.nombre == "" ||
        this.operador.apellido == ""
        //||
        // this.operador.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
     saveoperador() {
      if (this.validarcampos()) {
        if (this.operador != null) {
          this.operador.fecha_licencia = 
            this.operador.fecha_licencia;
         
          this.operador.fecha_vigencia = 
            this.operador.fecha_vigencia;
         

          console.log(this.operador);
          this.axios
            .post(url + "apiOperadores.php/operador", this.operador)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data != 0) {
                this.edit=false
                this.veroperadores();
                if (this.operador.documentacion.ine != null) {
                  console.log("xxx");
                  console.log(
                    this.uploadFile(
                      this.operador.documentacion.ine,
                      respuesta.data
                    )
                  );
                }

                if (this.operador.documentacion.licencia != null) {
                  this.uploadFilelic(
                    this.operador.documentacion.licencia,
                    respuesta.data
                  );
                }

                if (this.operador.documentacion.examen_medico != null) {
                  this.uploadFileExamen(
                    this.operador.documentacion.examen_medico,
                    respuesta.data
                  );
                }
              }
            })
            .catch(function () {});
        }
      } else {
        this.$swal("Debe ingresar nombre y apellido");
      }
    },
    uploadFile(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("ine", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadINE",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },
    uploadFilelic(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("licencia", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadlicencia",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },
    uploadFileExamen(file, idoperador) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idoperador", idoperador);
      formData.append("examen_medico", file);
      this.axios
        .post(
          url + "apiOperadores.php/uploadexamen_medico",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.veroperadores();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    }
    }
      
    }
</script>
